import React, { useEffect, useState, SetStateAction, Dispatch, useMemo } from "react";
import "./modal.css";
import DataGrid from '../table/data_grid';
import SelectedListItem from "../list/nodes_list";
import {JrpcServer} from "../../jrpc/server/0_0_1/types";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper';
import { INodeCardData, INodeData } from "../common/types/nodeData";
import { IconButton, MenuItem, Select, Stack } from "@mui/material";
import { Cancel, Menu } from "@mui/icons-material";
import { NodeCardMenu } from "../nodeCardMenu/nodeCardMenu";
import { NodeRendererProps, Tree } from "react-arborist";
import Avatar from '@mui/material/Avatar';
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/joy";


interface IModalProps { 
    modalNodeData: INodeCardData
    setActive: Dispatch<SetStateAction<INodeCardData>>
    jRPCServer: JrpcServer
    openedNodes: INodeData[]
    selectedNode: number
    setSelectedNode: Dispatch<SetStateAction<number>>
    removeNode: (index: number, currentIndex: number)=>void
    onSelectChange: (id: number) => void
    getNodeCard: (nodeName: string) => void
}

const generateUniqueId = () => {
    const timestamp = Date.now().toString(36);
    const randomNumber = Math.random().toString(36).substr(2);
    return timestamp + randomNumber;
};

const createTableRows = (data: object): any[] => {
    if (!data) {
        return [];
    }
    return Object.entries(data).map(([name, data]) => ({id: generateUniqueId(), name, data }));
};

const setMaxStrLength = (text: string,value: number = 30) => {
    if (text.length > value) {
        return text.slice(0,value)+"..."
    }
    return text
}

const Modal = (props: IModalProps) => {
    const { modalNodeData, setActive, jRPCServer, openedNodes, selectedNode, removeNode, onSelectChange: handleNodeSelectChange, getNodeCard} = props;
    const [rows, setRows] = useState<any[]>([]);
    const isMobile = window.innerWidth < 600;
    const windowWidth = window.innerWidth;
    const setVisibleFalse = () => {
        setActive({...modalNodeData, visible: false});
    }
    
    function escFunction (ev: KeyboardEvent):void {
        if (ev.key === "Escape") {
            setVisibleFalse();}
    }

    useEffect(() => {
        if (!modalNodeData.isGroup) {
            const newRows = createTableRows(modalNodeData.nodeInfo);
            setRows(newRows);
        }
    }, [modalNodeData]);
    useEffect(() => {
        document.addEventListener("keydown", escFunction, true);
        return () => (
            document.removeEventListener("keydown", escFunction, true)
        )
    })

    let header = null;
    let data_part = null;
    const additionalColumns = [
        { 
            field: 'name', 
            headerName: 'Наименование', 
            editable: true,
            width: isMobile? windowWidth*.3-8:150,
        },
        {
          field: 'data',
          headerName: 'Данные',
          editable: true,
          width: isMobile? windowWidth*.7-8:400,
        },
    ];

    const Node = ({ node, style, dragHandle }: NodeRendererProps<any>) => {
        const elementPadding = node.data.children?.length ? node.level*20 : (node.level*20)+30;
        return (
    
          <div style={{...style, textAlign:"left", paddingLeft: elementPadding, height: "100%", display:"flex", alignItems:"center", backgroundColor: node.isSelected ? "#f5f5f5" : "white"}}
            ref={dragHandle} onClick={node.isLeaf? () => {getNodeCard(node.data.name)} : () => {node.toggle()}}
            >
            {!!node.data.children?.length && (
                <IconButton>
                    {node.isOpen ?<ArrowDropDownIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
                </IconButton>
                )}
                <Avatar alt="R" src={"/static/img/" + node.data.icon} sx={{ width: 24, height: 24 }}/>{node.data.name.length > 20 ? setMaxStrLength(node.data.name) : node.data.name}
          </div>
        );
      }

    header = (
        <div className="node_header">
            <div>
            <IconButton size="medium" onClick={() => setVisibleFalse()}><ArrowBackIosIcon fontSize="inherit"/></IconButton>
            </div>
            <div style={{width: "100%"}}>
            <Select 
                id="selectCardDropdown"
                size="small" value={selectedNode || ''} 
                sx={{color: "black", marginBlockEnd: 1}} 
                fullWidth={true} 
                onChange={(e) => {handleNodeSelectChange(e.target.value as number);}}
                renderValue={(value) => setMaxStrLength(openedNodes[value as number]?.name || "", 30)}
                >
                    {openedNodes.map((node, indx)=> 
                        (!! indx &&
                        <MenuItem value={indx} key={node.name} sx={{display: "flex", justifyContent: "space-between", fontSize: isMobile ? ".8rem" : "1em"}} selected={indx===selectedNode}>
                            {setMaxStrLength(node.name, 35)}
                            <IconButton aria-label="delete" onClick={(e)=>{
                                e.preventDefault(); 
                                e.stopPropagation(); 
                                removeNode(indx, selectedNode)
                                }}>
                                <Cancel fontSize="small" sx={{padding: 0}}/>
                            </IconButton>
                        </MenuItem>
                        )
                    )}
                </Select>
                </div>
                <div><NodeCardMenu nodeName={openedNodes[selectedNode]?.name || ""} jrpcServerClient={jRPCServer} /></div>
        </div>
    )

    if (modalNodeData.isGroup) {
        data_part = (
            <div className="data-part group">
                <SelectedListItem data={modalNodeData} jRPCServer={props.jRPCServer} openNodeCard={getNodeCard}/>
            </div>
        )
    } else {
        data_part = (
        <>
            <div className="data-part container group" style={{maxHeight: "50dvh"}}>
                <DataGrid
                    rows={rows}
                    setRows={setRows}
                    additionalColumns={additionalColumns}
                    onRemoveNodeAttr={(attrName)=>{jRPCServer.removeNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName})}}
                    onSetNodeAttr={(attrName, attrValue) => {jRPCServer.setNodeAttr({node_name: modalNodeData.nodeName, attr_name: attrName, attr_value: attrValue})}}
                    />
            </div>
            {modalNodeData.relations?.length ?
            <Accordion sx={{maxHeight: "100%", marginTop: 1, borderBlock: "2px solid #e0e0e0"}}>
                <AccordionSummary>
                        <p>Связи</p>
                </AccordionSummary>
            <AccordionDetails style={{}}>

            <div>
                     <Tree
                    data={modalNodeData.relations}
                    openByDefault={false}
                    width={"100%"}
                    height={200}
                    rowHeight={36}
                    disableDrag={true}
                    disableEdit={true}
                    
                    >
                    {Node}
                </Tree>
            </div>
                    </AccordionDetails>
            </Accordion>
            :
            ""
    }
        </>
        )
    }

    return (
        <Paper className={`modal ${modalNodeData.visible ? 'active' : ''}`}>
            <div className="modal__content" onClick={e => e.stopPropagation()}>
                {header}
                {data_part}
            </div>
        </Paper>
    );
};

export default Modal;